import './App.css';
import Webpages from './routes/Router';

function App() {
  return (
    <div className="App">
      <Webpages/>
    </div>
  );
}

export default App;